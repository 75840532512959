<ng-container *transloco="let t">
    <h2 mat-dialog-title>{{ data.title ?? t('CONFIRM') }}</h2>
    <div mat-dialog-content class="full-width" [innerHTML]="data.text"></div>
    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="false">{{ data.buttonCancel ?? t('CANCEL') }}</button>
        <button mat-flat-button [mat-dialog-close]="true">
            {{ data.buttonConfirm ?? t('CONFIRM') }}
        </button>
    </mat-dialog-actions>
</ng-container>
